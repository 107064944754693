import ModalComponent from "@components/modal/Modal.component";
import { Colors } from "@constants/colors.constant";
import { Button, Stack, Typography, keyframes } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Application, ApplicationDetails } from "@store/applications";
import { TwilioAiCall, TwilioCallParticipantTypeEnum, TwilioCallStatusEnum, twilioAiCallsService } from "@store/twilioAiCalls";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
`;

const BouncingDot = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  width: "8px",
  height: "8px",
  backgroundColor: "#000",
  animation: `${bounce} 1s infinite`,
}));

interface ChatRowProps {
  message?: string;
  participantType?: TwilioCallParticipantTypeEnum;
  loading?: boolean;
  application: ApplicationDetails | Application;
}

const ChatRow = (props: ChatRowProps) => {
  const { message, participantType, loading, application } = props;

  return (
    <Stack direction={participantType === TwilioCallParticipantTypeEnum.AI ? "row" : "row-reverse"} alignItems={"center"} spacing={"10px"}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={0}
        sx={{
          borderRadius: "50%",
          border: "1.5px solid white",
          background: "white",
          boxShadow: "0px 0px 4px 0px #00000040",
          width: "40px",
          height: "40px",
          overflow: "hidden",
        }}>
        <img
          style={{ objectFit: "cover", width: participantType === TwilioCallParticipantTypeEnum.AI ? "90%" : "100%" }}
          src={
            participantType === TwilioCallParticipantTypeEnum.AI
              ? "/images/ia_robot.svg"
              : application.candidate?.picture ?? "/images/icon_users_grey.svg"
          }
          alt="avatar"
        />
      </Stack>
      {loading ? (
        <Stack direction="row" spacing={"4px"} alignItems="center" justifyContent="center">
          <BouncingDot />
          <BouncingDot style={{ animationDelay: "0.2s" }} />
          <BouncingDot style={{ animationDelay: "0.4s" }} />
        </Stack>
      ) : (
        <Stack padding={"10px 16px 10px 16px"} bgcolor={"white"} borderRadius={"15px"}>
          <Typography fontSize={"12px"} fontWeight={"400"} textAlign="left" alignSelf="center">
            {message}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

function getCurrentName(application: ApplicationDetails | Application) {
  return application.customName || application.candidate?.fullName || application.name;
}
interface CallChatModalProps {
  handleClose: () => void;
  twilioAiCall: TwilioAiCall;
  application: ApplicationDetails | Application;
}

const CallChatModal = (props: CallChatModalProps) => {
  const { handleClose, twilioAiCall, application } = props;
  const { t } = useTranslation();

  const [callDuration, setCallDuration] = useState("");

  const getCallDuration = useCallback(() => {
    const endTime =
      twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED && twilioAiCall.completedAt
        ? new Date(twilioAiCall.completedAt).getTime()
        : new Date().getTime();
    const duration =
      twilioAiCall.callStatus === TwilioCallStatusEnum.FAILED ||
      twilioAiCall.callStatus === TwilioCallStatusEnum.BUSY ||
      twilioAiCall.callStatus === TwilioCallStatusEnum.NO_ANSWER
        ? 0
        : endTime - new Date(twilioAiCall.createdAt).getTime();
    const minutes = Math.floor(duration / 60000);
    const seconds = Math.floor((duration % 60000) / 1000)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  }, [twilioAiCall]);
  useEffect(() => {
    if (twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED) {
      setCallDuration(getCallDuration());
    } else {
      const interval = setInterval(() => {
        setCallDuration(getCallDuration());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [getCallDuration, twilioAiCall]);

  const downloadCall = () => {
    twilioAiCallsService.downloadCall(twilioAiCall.callSid).subscribe({
      next: (callURL) => {
        const link = document.createElement("a");
        link.href = callURL;
        link.target = "_blank";
        link.download = "call_recording.mp3";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  return (
    <ModalComponent
      maxWidth={"xs"}
      handleClose={handleClose}
      content={
        <Stack spacing={1} pt={"5px"} alignItems={"flex-start"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography fontSize={"20px"} fontWeight={"700"} textAlign="center" alignSelf="center">
              {twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED
                ? t("ads.details.applyTab.aiCalls.callCompleted")
                : t("ads.details.applyTab.aiCalls.inProgress")}
            </Typography>
            <Typography fontSize={"20px"} fontWeight={"300"} textAlign="center" alignSelf="center">
              {" : " + getCurrentName(application)}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <Stack direction={"row"} alignItems={"center"} spacing={"10px"}>
              {twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED ? (
                <img src="/images/icon_phone_primary.svg" alt="icon_phone_completed" />
              ) : (
                <img src="/images/icon_phone_ringing.svg" alt="icon_phone_ringing" />
              )}
              <Typography
                fontSize={"14px"}
                fontWeight={"300"}
                textAlign="center"
                alignSelf="center"
                color={twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED ? "#E8614D" : "#8FD07C"}>
                {callDuration}
              </Typography>
            </Stack>
            {twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED && (
              <Button onClick={() => downloadCall()} variant="text" color="primary">
                <img src="/images/download_media.svg" alt="download" />
                <Typography ml={"10px"} fontSize={"14px"} fontWeight={"400"} textAlign="center" alignSelf="center">
                  {t("ads.details.applyTab.aiCalls.downloadCall")}
                </Typography>
              </Button>
            )}
          </Stack>
          <Stack bgcolor={Colors.greyCardBg} borderRadius={"10px"} padding={"20px 20px 20px 15px"} spacing={"25px"} width={"100%"}>
            {!twilioAiCall.conversation.length && twilioAiCall.callStatus === TwilioCallStatusEnum.COMPLETED ? (
              <Typography fontSize={"16px"} fontWeight={"700"} textAlign="center" alignSelf="center">
                {t("ads.details.applyTab.aiCalls.noMessages")}
              </Typography>
            ) : !twilioAiCall.conversation.length && twilioAiCall.callStatus !== TwilioCallStatusEnum.COMPLETED ? (
              <ChatRow message="" participantType={TwilioCallParticipantTypeEnum.AI} application={application} loading />
            ) : (
              twilioAiCall.conversation.map((message, index) => (
                <ChatRow key={index} message={message.content} participantType={message.participantType} application={application} />
              ))
            )}
            {!!twilioAiCall.conversation.length && twilioAiCall.callStatus !== TwilioCallStatusEnum.COMPLETED && (
              <ChatRow
                message={""}
                participantType={
                  twilioAiCall.conversation[twilioAiCall.conversation.length - 1].participantType === TwilioCallParticipantTypeEnum.AI
                    ? TwilioCallParticipantTypeEnum.RECIPIENT
                    : TwilioCallParticipantTypeEnum.AI
                }
                application={application}
                loading
              />
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default CallChatModal;
