import { Components, alpha } from "@mui/material";
import { Colors } from "@constants/colors.constant";

const tooltip: Components = {
  MuiTooltip: {
    defaultProps: {
      enterTouchDelay: 0,
    },
    styleOverrides: {
      popper: {
        '&[aria-label="variant-primary"] .MuiTooltip-tooltip': {
          background: alpha(Colors.primary, 0.1),
          color: Colors.primary,
          borderRadius: 5,
          padding: "5px 10px",
        },
      },
      tooltip: {
        background: Colors.tooltipBackground,
        backdropFilter: "blur(5px)",
        borderRadius: 15,
        padding: "15px 20px",
        fontSize: "14px",
      },
      arrow: {
        color: Colors.tooltipBackground,
      },
    },
  },
};

export default tooltip;
