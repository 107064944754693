import { useMemo, useState } from "react";
import { Avatar, Box, Checkbox, FormControlLabel, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { ConnectorMediaEnum, ConnectorMetaData, connectorsService, connectorsStore, ConnectorTypeEnum, ManageConnector } from "@store/connectors";

import { FieldValidationType, getFieldError } from "@utils/yup.utils";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import ConfirmModal from "@components/modal/Confirm.modal";
import { AddBox } from "@mui/icons-material";
import { deleteEntities } from "@ngneat/elf-entities";

interface ActivateConnectorModalProps {
  affiliateId: string;
  connectorId?: string;
  connectorMultiAccounts?: string[];
  connectorMetaData: ConnectorMetaData;
  handleClose: () => void;
  isCreation?: boolean;
}

type ErrorType = {
  [key: string]: any; // Permet des clés dynamiques
};

const ActivateConnectorModal = (props: ActivateConnectorModalProps) => {
  const { affiliateId, connectorId, connectorMultiAccounts, connectorMetaData, handleClose, isCreation } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [hasMultipleAccounts, setHasMultipleAccounts] = useState<boolean | undefined>(isCreation ? undefined : true);
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean | undefined>(undefined);
  const [justActivatedConnectorId, setJustActivatedConnectorId] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ManageConnector>({
    affiliateId,
    media: connectorMetaData.media,
    type: connectorMetaData.type,
    multiCredentials:
      connectorMetaData.media === ConnectorMediaEnum.FRANCE_TRAVAIL
        ? connectorMultiAccounts?.map((login) => ({
            login: login,
            password: "",
          })) ?? [{ login: "", password: "" }]
        : undefined,
  });

  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [accountsState, setAccountsState] = useState<("active" | "inactive" | "loading")[]>(
    data.multiCredentials?.map((account) => (account.login.length > 0 ? "active" : "inactive")) ?? []
  );

  const errors: ErrorType = useMemo(() => {
    return {
      ...(connectorMetaData.type === ConnectorTypeEnum.PASSWORD && hasMultipleAccounts !== true
        ? {
            login: getFieldError(data.login, FieldValidationType.REQUIRED_STRING),
            password: getFieldError(data.password, FieldValidationType.REQUIRED_STRING),
          }
        : {}),
      ...(connectorMetaData.type === ConnectorTypeEnum.PASSWORD && hasMultipleAccounts === true
        ? data.multiCredentials?.reduce((acc: ErrorType, credential, index) => {
            acc[`login_${index}`] = getFieldError(credential.login, FieldValidationType.REQUIRED_STRING);
            acc[`password_${index}`] = getFieldError(credential.password, FieldValidationType.REQUIRED_STRING);
            return acc;
          }, {})
        : {}),
      ...(connectorMetaData.type === ConnectorTypeEnum.URL
        ? {
            company: getFieldError(data.company, FieldValidationType.REQUIRED_STRING),
            url: getFieldError(data.url, FieldValidationType.REQUIRED_STRING),
          }
        : {}),
    };
  }, [connectorMetaData.type, data.company, data.login, data.multiCredentials, data.password, data.url, hasMultipleAccounts]);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const updateMulticrendentialsData = (field: string, index: number) => (value: any) => {
    setData((state) => ({
      ...state,
      multiCredentials: state.multiCredentials?.map((credential, i) => (i === index ? { ...credential, [field]: value } : credential)),
    }));
  };

  const handleActivate = () => {
    if (isCreation) {
      setLoading(true);
      connectorsService
        .activateConnector(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("connectors.success.activated"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      if (!connectorId) return;
      setLoading(true);
      connectorsService
        .updateConnector(connectorId, data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("connectors.success.updated"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  const handleActivateAccount = (index: number) => {
    if (data.multiCredentials === undefined) return;
    if (isCreation) {
      setAccountsState((state) => state.map((state, i) => (i === index ? "loading" : state)));
      connectorsService
        .activateConnector({ ...data, login: data.multiCredentials[index].login, password: data.multiCredentials[index].password })
        .subscribe({
          next: (connector) => {
            enqueueSnackbar(t("connectors.success.activated"), { variant: "success" });
            setAccountsState((state) => state.map((state, i) => (i === index ? "active" : state)));
            setJustActivatedConnectorId(connector.id);
          },
          error: (err) => {
            enqueueSnackbar(err.text, err.options);
            setAccountsState((state) => state.map((state, i) => (i === index ? "inactive" : state)));
          },
        });
    } else {
      if (!connectorId) return;
      setAccountsState((state) => state.map((state, i) => (i === index ? "loading" : state)));
      connectorsService
        .updateConnector(connectorId, { ...data, login: data.multiCredentials[index].login, password: data.multiCredentials[index].password })
        .subscribe({
          next: () => {
            enqueueSnackbar(t("connectors.success.updated"), { variant: "success" });
            setAccountsState((state) => state.map((state, i) => (i === index ? "active" : state)));
          },
          error: (err) => {
            enqueueSnackbar(err.text, err.options);
            setAccountsState((state) => state.map((state, i) => (i === index ? "inactive" : state)));
          },
        });
    }
  };

  const handleDeactivateAccount = (index: number) => {
    if (isCreation) {
      if (!justActivatedConnectorId) return;
      setAccountsState((state) => state.map((state, i) => (i === index ? "loading" : state)));
      connectorsService.deactivateOneAccountFromConnector(justActivatedConnectorId, data?.multiCredentials?.[index]?.login).subscribe({
        next: () => {
          enqueueSnackbar(t("connectors.success.deactivated"), { variant: "success" });
          setAccountsState((state) => state.filter((_, i) => i !== index));
          setData((state) => ({
            ...state,
            multiCredentials: state.multiCredentials?.filter((_, i) => i !== index),
          }));
          if (accountsState.filter((state) => state === "active").length === 1) {
            connectorsStore.update(deleteEntities(justActivatedConnectorId));
            handleClose();
          }
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    } else {
      if (!connectorId) return;
      setAccountsState((state) => state.map((state, i) => (i === index ? "loading" : state)));
      connectorsService.deactivateOneAccountFromConnector(connectorId, data?.multiCredentials?.[index]?.login).subscribe({
        next: () => {
          enqueueSnackbar(t("connectors.success.deactivated"), { variant: "success" });
          setAccountsState((state) => state.filter((_, i) => i !== index));
          setData((state) => ({
            ...state,
            multiCredentials: state.multiCredentials?.filter((_, i) => i !== index),
          }));
          if (accountsState.filter((state) => state === "active").length === 1) {
            connectorsStore.update(deleteEntities(connectorId));
            handleClose();
          }
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  const addAccount = () => {
    setData((state) => ({
      ...state,
      multiCredentials: state.multiCredentials?.concat({ login: "", password: "" }),
    }));
    setAccountsState((state) => state.concat("inactive"));
  };

  if (connectorMetaData.type === ConnectorTypeEnum.STANDALONE) {
    return (
      <ConfirmModal
        closeOnDemand
        logo={connectorMetaData.logo}
        handleClose={handleClose}
        handleConfirm={handleActivate}
        confirmMsg={t("connectors.confirmModal.messageActivate")}
        modalTitle={connectorMetaData.name}
        hideCancel
      />
    );
  }

  if (connectorMetaData.media === ConnectorMediaEnum.FRANCE_TRAVAIL && hasMultipleAccounts === undefined) {
    return (
      <ModalComponent
        handleClose={handleClose}
        title={connectorMetaData.name}
        content={
          <Stack p={"15px"} alignItems={"center"} spacing={3}>
            {connectorMetaData.logo}
            <Stack spacing={3} width={"100%"} color={"grey"}>
              <Typography fontWeight={500} fontSize="15px">
                {t("connectors.howManyAccounts", { media: "France Travail" })}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color={"default"}
                    checked={selectedCheckbox === false}
                    onChange={() => setSelectedCheckbox(selectedCheckbox === false ? undefined : false)}
                  />
                }
                label={
                  <Typography ml="10px" fontSize="16px" lineHeight="18px" fontWeight={300}>
                    {t("connectors.singleAccount")}
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color={"default"}
                    checked={selectedCheckbox === true}
                    onChange={() => setSelectedCheckbox(selectedCheckbox === true ? undefined : true)}
                  />
                }
                label={
                  <Typography ml="10px" fontSize="16px" lineHeight="18px" fontWeight={300}>
                    {t("connectors.multipleAccounts")}
                  </Typography>
                }
              />
            </Stack>
          </Stack>
        }
        maxWidth="xs"
        paddingButton="10px 0px 40px 0px"
        actions={
          <AIOButtonComponent
            title={t("global.continue")}
            ariaLabel="modal"
            variant="contained"
            disabled={loading || selectedCheckbox === undefined}
            onClick={() => setHasMultipleAccounts(selectedCheckbox)}
          />
        }
      />
    );
  }

  const handleRemoveLine = (index: number) => {
    setData((state) => ({
      ...state,
      multiCredentials: state.multiCredentials?.filter((_, i) => i !== index),
    }));
    setAccountsState((state) => state.filter((_, i) => i !== index));
  };

  if (connectorMetaData.media === ConnectorMediaEnum.FRANCE_TRAVAIL && hasMultipleAccounts === true) {
    return (
      <ModalComponent
        handleClose={handleClose}
        title={connectorMetaData.name}
        content={
          <Stack alignItems={"center"} p="15px" spacing={3}>
            {connectorMetaData.logo}

            <Stack direction={"row"} width="100%" justifyContent={"space-between"} alignItems={"center"}>
              <Typography color={"grey"} fontSize="16px" fontWeight={500}>
                {t("connectors.mediaAccounts", { media: "France Travail" })}
              </Typography>
              <AIOButtonComponent title={t("connectors.addAccount")} variant="text" endIcon={<AddBox />} onClick={addAccount} />
            </Stack>

            {data.multiCredentials?.map((credential, index) => (
              <Stack key={index} direction={"row"} alignItems={"center"} width="100%" spacing={1}>
                {accountsState[index] === "inactive" && (
                  <Box>
                    <IconButton onClick={() => handleRemoveLine(index)}>
                      <img alt="delete" src="/images/delete.svg" />
                    </IconButton>
                  </Box>
                )}
                <Box width={accountsState[index] === "inactive" ? "40%" : "80%"}>
                  <AIOTextfieldComponent
                    autoComplete="off"
                    placeholder={t("connectors.login")}
                    onChange={updateMulticrendentialsData("login", index)}
                    value={credential.login}
                    error={accountsState[index] === "inactive" && errors?.[`login_${index}`]}
                    fullWidth
                    required={accountsState[index] === "inactive"}
                    readonly={accountsState[index] === "active"}
                  />
                </Box>
                {accountsState[index] === "inactive" && (
                  <Box>
                    <AIOTextfieldComponent
                      autoComplete="off"
                      placeholder={t("connectors.password")}
                      onChange={updateMulticrendentialsData("password", index)}
                      value={credential.password}
                      error={errors?.[`password_${index}`]}
                      endAdornment={
                        <IconButton size="small" sx={{ mr: "-5px" }} onClick={() => setShowPassword((state) => !state)}>
                          <img alt="show password" src={`/images/icon_${showPassword ? "hide" : "show"}_pwd_primary.svg`} />
                        </IconButton>
                      }
                      type={showPassword ? undefined : "password"}
                      fullWidth
                      required
                    />
                  </Box>
                )}
                <Box width="20%">
                  <AIOButtonComponent
                    disabled={accountsState[index] === "loading" || (accountsState[index] === "inactive" && !confirmationChecked)}
                    title={
                      accountsState[index] === "active"
                        ? t("global.deactivate")
                        : accountsState[index] === "inactive"
                        ? t("global.activate")
                        : t("global.loading")
                    }
                    variant="contained"
                    fullWidth
                    onClick={() => (accountsState[index] === "active" ? handleDeactivateAccount(index) : handleActivateAccount(index))}
                  />
                </Box>
              </Stack>
            ))}

            <Stack pt="10px" spacing={3}>
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                checked={confirmationChecked}
                onChange={(_, evt) => setConfirmationChecked(evt)}
                control={<Checkbox />}
                label={
                  <Typography ml="10px" fontSize="12px" lineHeight="1.25" fontWeight="300">
                    {t(`connectors.confirmModal.legalConfirm.${connectorMetaData.media}`)}
                  </Typography>
                }
              />
            </Stack>
          </Stack>
        }
        maxWidth="sm"
        paddingButton="10px 0px 40px 0px"
      />
    );
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title={connectorMetaData.name}
      content={
        <Stack alignItems={"center"} py="15px" spacing={3}>
          {connectorMetaData.logo}

          {connectorMetaData.type === ConnectorTypeEnum.PASSWORD && (
            <Stack width="100%" spacing={3}>
              <Box width="100%">
                <AIOTextfieldComponent
                  autoComplete="off"
                  placeholder={t("connectors.login")}
                  title={t("connectors.login")}
                  onChange={updateData("login")}
                  value={data.login}
                  error={errors.login}
                  fullWidth
                />
              </Box>
              <Box width="100%">
                <AIOTextfieldComponent
                  autoComplete="off"
                  title={t("connectors.password")}
                  placeholder={t("connectors.password")}
                  onChange={updateData("password")}
                  value={data.password || ""}
                  error={errors.password}
                  endAdornment={
                    <IconButton size="small" sx={{ mr: "-5px" }} onClick={() => setShowPassword((state) => !state)}>
                      <img alt="show password" src={`/images/icon_${showPassword ? "hide" : "show"}_pwd_primary.svg`} />
                    </IconButton>
                  }
                  type={showPassword ? undefined : "password"}
                  fullWidth
                  required
                />
              </Box>
            </Stack>
          )}

          {connectorMetaData.type === ConnectorTypeEnum.URL && (
            <Stack width="100%" spacing={3}>
              <Box width="100%">
                <AIOTextfieldComponent
                  autoComplete="off"
                  placeholder={t("connectors.company")}
                  title={t("connectors.company")}
                  onChange={updateData("company")}
                  value={data.company}
                  error={errors.company}
                  fullWidth
                  required
                />
              </Box>
              <Box width="100%">
                <AIOTextfieldComponent
                  autoComplete="off"
                  title={t("connectors.url", { media: connectorMetaData.name })}
                  placeholder={t("connectors.url", { media: connectorMetaData.name })}
                  onChange={updateData("url")}
                  value={data.url || ""}
                  error={errors.url}
                  fullWidth
                  required
                />
              </Box>
              {connectorMetaData.media === ConnectorMediaEnum.LINKEDIN && (
                <>
                  <Box width="100%">
                    <AIOTextfieldComponent
                      autoComplete="off"
                      title={t("connectors.companyId")}
                      placeholder={t("connectors.companyId")}
                      onChange={updateData("linkedinCompanyId")}
                      value={data.linkedinCompanyId || ""}
                      endAdornment={
                        <Tooltip sx={{ fontSize: "20px" }} title={t("connectors.linkedinCompanyIdTooltip")} arrow placement="bottom">
                          <img alt="information" height="14px" width="14px" src="/images/icon_informations.svg" />
                        </Tooltip>
                      }
                      fullWidth
                      required
                    />
                  </Box>
                  <Stack width="100%" spacing={3} bgcolor={"#E9624D17"} color={"grey"} borderRadius={"13px"} p={"24px"}>
                    <Typography fontSize="12px" fontWeight={500}>
                      {t("connectors.linkedinWarning")}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={5}>
                      <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <Avatar sx={{ width: "42px", height: "42px" }} alt="Vanessa Pichard" src="/images/connectors/linkedin-avatar-vanessa.png" />
                        <Link
                          href={"https://www.linkedin.com/in/vanessa-pichard-b72702236/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                          fontSize="12px"
                          fontWeight={500}>
                          Vanessa Pichard
                        </Link>
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <Avatar sx={{ width: "42px", height: "42px" }} alt="Adrien Henrival" src="/images/connectors/linkedin-avatar-adrien.png" />
                        <Link
                          href={"https://www.linkedin.com/in/adrien-henrival-b7594b282/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="inherit"
                          fontSize="12px"
                          fontWeight={500}>
                          Adrien Henrival
                        </Link>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          )}

          <Stack pt="10px" spacing={3}>
            <FormControlLabel
              sx={{ alignItems: "flex-start" }}
              checked={confirmationChecked}
              onChange={(_, evt) => setConfirmationChecked(evt)}
              control={<Checkbox />}
              label={
                <Typography ml="10px" fontSize="12px" lineHeight="1.25" fontWeight="300">
                  {t(`connectors.confirmModal.legalConfirm.${connectorMetaData.media}`)}
                </Typography>
              }
            />
          </Stack>
        </Stack>
      }
      maxWidth="xs"
      paddingButton="10px 0px 40px 0px"
      actions={
        <AIOButtonComponent
          title={t("global.confirm")}
          ariaLabel="modal"
          variant="contained"
          disabled={loading || !confirmationChecked}
          onClick={handleActivate}
        />
      }
    />
  );
};

export default ActivateConnectorModal;
